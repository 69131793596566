import Vue from "vue";

/*TITLE*/
document.title = "Residencial Montevidal | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Montevidal";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Montevidal";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-vela-smoked-v2--20230116100125.jpg";
Vue.prototype.$image_kitchen = "cocina-cerrada-linkfloor-feudal-bottega-caliza-eucalyptus-gray-v2--20230116100139.jpg";
Vue.prototype.$image_bath1 = "banop-op4.-urban-caliza-nature-urban-natural-nature-cubik-urban-natural-nature-mueble-blanco-elementos-en-negro-v3--20230116100123.jpg";
Vue.prototype.$image_bath2 = "banos-op3.-matika-topo-matika-topo-stripe-matika-topo-mueble-eucalyptus-blond-elementos-en-cromo-v3--20230116100157.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-vela-smoked-v2--20230116100125.jpg",
  },
  {
    src: "salon-vela-natural-v2--20230116100132.jpg",
  },
  {
    src: "salon-vela-black-v2--20230116100139.jpg",
  },
  {
    src: "salon-linkfloor-feudal-natural-v2--20230116100146.jpg",
  },
  {
    src: "salon-linkfloor-feudal-grey-v2--20230116100154.jpg",
  },
  {
    src: "salon-linkfloor-feudal-beige-v2--20230116100107.jpg",
  },
  {
    src: "salon-forest-natural-v2--20230116100114.jpg",
  },
  {
    src: "salon-forest-colonial-v2--20230116100123.jpg",
  },
  {
    src: "salon-forest-acero-v2--20230116100130.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-cerrada-linkfloor-feudal-bottega-caliza-eucalyptus-gray-v2--20230116100139.jpg",
  },
  {
    src: "cocina-cerrada-forest-natural-bottega-acero-blanco-nubol-eucalyptus-blond-v2--20230116100146.jpg",
  },
  {
    src: "cocina-abierta-linkfloor-feudal-naturalbottega-antracita-blanco-nubol-eucalyptus-candy-v2--20230116100154.jpg",
  },
  {
    src: "cocina-abierta-linkfloor-feudal-natural-krion-eucalyptus-blond-eucalyptus-smutty-v2--20230116100101.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-op4.-urban-caliza-nature-urban-natural-nature-cubik-urban-natural-nature-mueble-blanco-elementos-en-negro-v3--20230116100123.jpg",
  },
  {
    src: "banop-op3.-urban-black-nature-urban-black-nature-limit-urban-black-nature-mueble-roble-taupe-elementos-en-negro-v3--20230116100133.jpg",
  },
  {
    src: "banop-op2.-urban-black-nature-urban-black-nature-cubik-urban-black-nature-mueble-beige-elementos-en-cromo-v3--20230116100142.jpg",
  },
  {
    src: "banop-op1-urban-acero-nature-urban-acero-nature-cubik-urban-acero-nature-mueble-roble-taupe-elementos-en-negro-v3--20230116100149.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banos-op3.-matika-topo-matika-topo-stripe-matika-topo-mueble-eucalyptus-blond-elementos-en-cromo-v3--20230116100157.jpg",
  },
  {
    src: "banos-op2.-matika-silver-matika-silver-stripe-matika-silver-mueble-eucalyptus-candy-elementos-en-negro-v3--20230116100104.jpg",
  },
  {
    src: "banos-op1.-matika-bone-matika-bone-stripe-matika-bone-mueble-blanco-elementos-en-negro-v3--20230116100111.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/MONTEVIDAL/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/774969333";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20de%20pablo%20vidal%2028033%20madrid%20",
    text: "C/ De Pablo Vidal, 28033 (Madrid)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20nuria%2036%20madrid%202ª%20planta%20despacho%202%2011%20carolina%20fungairiño%20",
    text: "C/ Nuria, 36 (Madrid), 2ª planta. Despacho 2.11 - Carolina Fungairiño.",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:917356031",
    text: "917356031",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@montevidal.com",
    text: "info@montevidal.com",
  },
];
